/**
* Created by hansxing on 2018/8/2.
*/
<style lang="scss" scoped>
.mechanism-list {
    &.eTrip-section-cont {
        /*min-width: 1200px;*/
    }

    .screen-license-info {
        .el-col-3 {
            width: 135px;
            margin-right: 10px;
        }

    }

    .screen-license-info {
        margin-bottom: 15px;
    }

    .screen-checked-com {
        height: 20px;
        line-height: 20px;
        margin-bottom: 15px;
        white-space: nowrap;
        font-size: 12px;

        .el-col-2 {
            width: 66px
        }

        .el-col-22 {
            width: calc(100% - 66px);
            width: -moz-calc(100% - 66px);
            width: -webkit-calc(100% - 66px);
        }

        .checked-com-all.el-checkbox {
            display: inline;
        }

        .checked-com-group.el-checkbox-group {
            display: inline;
            margin-left: 10px;
        }
    }

    .screen-operate-btn {
        position: absolute;
        bottom: 10px;
        right: 10px;
        background: #fff;

    }

    .mechanism-list-table {
        background: #fff;
        margin-top: 10px;
        /*padding: 0 20px;*/
        .not-through-popover {
            display: inline-block;
            cursor: pointer;

            .el-popover__reference {
                color: #FF3B30;
            }
        }

    }

}

.mechanism-list /deep/ {
    .table-info {
        .el-table__fixed-right {
            right: 10px !important;
        }
    }
}

</style>
<style lang="scss">
.mechanism-list {
    .mechanism-list-screen {
        .el-radio-button:first-child {
            .el-radio-button__inner {
                padding: 12px 25px;
            }
        }

        .el-radio-button__inner {
            padding: 12px 10px;
        }
    }

    .common-list-search {
        padding: 10px 20px 10px 20px;

        .list-search-before {
            width: calc(100% - 260px);
        }

        .list-search-after {
            width: 260px;
            text-align: right;

            .operate-btn-t {
                background: transparent;
                border: 0;
                padding: 5px;
                color: #409EFF;
            }
        }

    }
}

.not-through-popover-cont {
    color: #FF3B30;

    .el-popover__title {
        color: #FF3B30;
    }
}

.screen-checked-com {
    .el-checkbox {
        .el-checkbox__label {
            padding-left: 5px;
            font-size: 12px;
        }
    }

    .checked-com-group {
        .el-checkbox + .el-checkbox {
            margin-left: 15px;
        }
    }
}

.mechanism-list-table {
    .table-info-operation {
        .el-button {
            padding: 0;
        }
    }

    td.is-center {
        &.table-info-operation {
            text-align: left;
        }
    }
}


</style>
<template>
    <div>
        <el-main class="com-eTrip-section">
            <div class="mechanism-list eTrip-section-cont">
                <com-list-search>
                    <div slot="list-search-before">
                        <el-form :inline="true" size="small">
                            <el-form-item label="" class="el-label_w" style="width: auto">
                                <el-radio-group v-model="companyTypeState.screenAuditState">
                                    <el-radio-button label="0">全部</el-radio-button>
                                    <el-radio-button label="1">待提交<span
                                        v-text="statisticalPageHead.approvalStatusInitCount"></span>
                                    </el-radio-button>
                                    <el-radio-button label="5">待初审
                                        <span v-text="statisticalPageHead.approvalStatusFirstCount"></span>
                                    </el-radio-button>
                                    <el-radio-button label="2">待复审<span
                                        v-text="statisticalPageHead.approvalStatusApprovalingCount"></span>
                                    </el-radio-button>
                                    <el-radio-button label="3">已通过<span
                                        v-text="statisticalPageHead.approvalStatusPassedCount"></span>
                                    </el-radio-button>
                                    <el-radio-button label="4">未通过<span
                                        v-text="statisticalPageHead.approvalStatusDeniedCount"></span>
                                    </el-radio-button>
                                    <el-radio-button label="6">初审未通过
                                        <!--                                        <span v-text="statisticalPageHead.approvalStatusDeniedCount"></span>-->
                                    </el-radio-button>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item label="" class="el-label_w" style="width: auto">
                                <el-radio-group v-model="companyTypeState.screenUserType">
                                    <el-radio-button label="1111">全部</el-radio-button>
                                    <el-radio-button label="1000">一级用户<span
                                        v-text="statisticalPageHead.firstMerchantCount"></span>
                                    </el-radio-button>
                                    <el-radio-button label="0100">二级用户<span
                                        v-text="statisticalPageHead.secondMerchantCount"></span>
                                    </el-radio-button>
                                    <el-radio-button label="0010">供应商<span
                                        v-text="statisticalPageHead.supplierCount"></span>
                                    </el-radio-button>
                                    <el-radio-button label="0001">运营商<span
                                        v-text="statisticalPageHead.operatorCount"></span>
                                    </el-radio-button>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item label="" class="el-label_w" style="width: auto">
                                <el-radio-group v-model="companyTypeState.screenContract">
                                    <el-radio-button label="0">全部</el-radio-button>
                                    <el-radio-button label="1">合同将过期<span
                                        v-text="statisticalPageHead.contractWillExpireCount"></span>
                                    </el-radio-button>
                                    <el-radio-button label="2">合同已过期<span
                                        v-text="statisticalPageHead.contractExpiredCount"></span>
                                    </el-radio-button>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item style="width: auto">
                                <el-radio-group v-model="companyTypeState.isClose">
                                    <el-radio-button label="null">全部</el-radio-button>
                                    <el-radio-button
                                        v-for="item in orgStatusList"
                                        :key="item.value"
                                        :label="item.value">
                                        {{ item.label }}
                                    </el-radio-button>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item label="">
                                <el-input v-model="companyInfoInput.mechanismId" placeholder="机构ID"></el-input>
                            </el-form-item>
                            <el-form-item label="" style="width: 200px">
                                <el-autocomplete
                                    class="inline-input"
                                    v-model="companyInfoInput.corporateName"
                                    :fetch-suggestions="corporateNameList"
                                    placeholder="请输入公司名称">
                                    <template slot-scope="{ item }">
                                        <div class="name">{{ item.value }}</div>
                                        <!--<span class="addr" >{{ item.address }}</span>-->
                                    </template>
                                </el-autocomplete>
                            </el-form-item>
                            <el-form-item label="">
                                <el-input v-model="companyInfoInput.businessContName"
                                          placeholder="业务联系人姓名"></el-input>
                            </el-form-item>
                            <el-form-item label="">
                                <el-input v-model="companyInfoInput.businessContMobile"
                                          placeholder="业务联系人手机"></el-input>
                            </el-form-item>
                            <el-collapse-transition>
                                <div v-if="companyTypeState.basicSeniorSwitch == true">
                                    <!--<el-row class="screen-license-info">-->
                                    <!--<el-col :span="3">-->
                                    <el-form-item label="">
                                        <el-autocomplete
                                            class="inline-input"
                                            v-model="companyInfoInput.serviceCity"
                                            :fetch-suggestions="galaxyCityList"
                                            placeholder="请输入服务城市"
                                            @select="galaxyCityId">
                                            <template slot-scope="{ item }">
                                                <div class="name">{{ item.value }}</div>
                                                <!--<span class="addr">{{ item.address }}</span>-->
                                            </template>
                                        </el-autocomplete>
                                    </el-form-item>
                                    <el-form-item label="">
                                        <el-input v-model="companyInfoInput.businessNumber"
                                                  placeholder="营业执照号码"></el-input>
                                    </el-form-item>
                                    <el-form-item label="">
                                        <el-input v-model="companyInfoInput.legalPersonName"
                                                  placeholder="机构法人姓名"></el-input>
                                    </el-form-item>
                                    <el-form-item label="">
                                        <el-input v-model="companyInfoInput.legalPersonMobile"
                                                  placeholder="机构法人手机"></el-input>
                                    </el-form-item>
                                    <el-form-item label="">
                                        <el-input v-model="companyInfoInput.financeName"
                                                  placeholder="财务联系人姓名"></el-input>
                                    </el-form-item>
                                    <el-form-item label="">
                                        <el-input v-model="companyInfoInput.financeMobile"
                                                  placeholder="财务联系人手机"></el-input>
                                    </el-form-item>
                                    <el-row class="screen-checked-com">
                                        <el-col :span="2">机构类型：</el-col>
                                        <el-col :span="22">
                                            <el-checkbox class="checked-com-all"
                                                         :indeterminate="companyTypeChoice.isIndeterminate"
                                                         v-model="companyTypeChoice.choiceAll"
                                                         @change="checkTypeAllChange">全部
                                            </el-checkbox>
                                            <el-checkbox-group class="checked-com-group"
                                                               v-model="companyTypeChoice.choiceTypeSelect"
                                                               @change="checkedTypeListChange">
                                                <el-checkbox
                                                    v-for="(item,companyTypeIndex) in companyTypeChoice.typeLists"
                                                    :label="item.typeLabel"
                                                    :key="companyTypeIndex">{{ item.typeTitle }}
                                                </el-checkbox>
                                            </el-checkbox-group>
                                        </el-col>
                                    </el-row>
                                    <el-row class="screen-checked-com">
                                        <el-col :span="2">经营范围：</el-col>
                                        <el-col :span="22">
                                            <el-checkbox class="checked-com-all"
                                                         :indeterminate="companyRangeChoice.isIndeterminate"
                                                         v-model="companyRangeChoice.choiceAll"
                                                         @change="checkRangeAllChange">全部
                                            </el-checkbox>
                                            <el-checkbox-group class="checked-com-group"
                                                               v-model="companyRangeChoice.choiceRangeSelect"
                                                               @change="checkedRangeListChange">
                                                <el-checkbox
                                                    v-for="(item,companyRangeIndex) in companyRangeChoice.rangeLists"
                                                    :label="item.rangeLabel"
                                                    :key="companyRangeIndex">{{ item.rangeTitle }}
                                                </el-checkbox>
                                            </el-checkbox-group>
                                        </el-col>
                                    </el-row>
                                </div>
                            </el-collapse-transition>

                        </el-form>
                    </div>
                    <div slot="list-search-after" class="list-search-after_sec">
                        <el-button class="operate-btn-t" @click="btnBasicSeniorS"
                                   v-if="companyTypeState.basicSeniorSwitch == false">
                            高级筛选<i class="el-icon-caret-bottom"></i></el-button>
                        <el-button class="operate-btn-t" @click="btnBasicSeniorS"
                                   v-if="companyTypeState.basicSeniorSwitch == true">
                            基本筛选<i class="el-icon-caret-top"></i></el-button>
                        <el-button @click="screenResetClick()">重置</el-button>
                        <el-button type="primary" @click="screenBtnClick()">搜索</el-button>
                    </div>
                </com-list-search>
                <div class="mechanism-list-table el-table_border_none">
                    <el-table class="table-info"
                              border
                              :data="companyTable"
                              v-loading="comTableLoading"
                              :height="tableH"
                              style="width: 100%">
                        <el-table-column
                            prop="orgId"
                            label="机构ID"
                            width="110">
                        </el-table-column>
                        <el-table-column
                            prop="companyName"
                            label="公司名称"
                            min-width="260px">
                        </el-table-column>
                        <el-table-column
                            label="业务联系人"
                            min-width="200">
                            <template slot-scope="scope">
                                <span v-text="scope.row.businessContractName"></span>
                                <span v-text="scope.row.businessContractPhone"></span>
                            </template>

                        </el-table-column>
                        <el-table-column
                            label="一级用户"
                            min-width="80">
                            <template slot-scope="scope">
                                <p v-if="scope.row.firstMerchantValidate == 0">无效</p>
                                <p v-if="scope.row.firstMerchantValidate == 1">有效</p>
                            </template>
                        </el-table-column>
                        <el-table-column
                            label="二级用户"
                            min-width="80">
                            <template slot-scope="scope">
                                <p v-if="scope.row.secondMerchantValidate == 0">无效</p>
                                <p v-if="scope.row.secondMerchantValidate == 1">有效</p>
                            </template>
                        </el-table-column>
                        <el-table-column
                            label="供应商"
                            min-width="80">
                            <template slot-scope="scope">
                                <p v-if="scope.row.supplierValidate == 0">无效</p>
                                <p v-if="scope.row.supplierValidate == 1">有效</p>
                            </template>
                        </el-table-column>
                        <el-table-column
                            label="运营商"
                            min-width="80">
                            <template slot-scope="scope">
                                <p v-if="scope.row.operatorValidate == 0">无效</p>
                                <p v-if="scope.row.operatorValidate == 1">有效</p>
                            </template>
                        </el-table-column>
                        <el-table-column
                            label="审核状态"
                            min-width="80">
                            <template slot-scope="scope">
                                <p v-if="!scope.row.approvalStatus || scope.row.approvalStatus == 1">待提交</p>
                                <p v-if="scope.row.approvalStatus == 2">待复审</p>
                                <p v-if="scope.row.approvalStatus == 5">待初审</p>
                                <p v-if="scope.row.approvalStatus == 6">初审未通过</p>
                                <!--<p v-if="scope.row.approvalStatus == 3">已通过</p>-->
                                <el-popover class="not-through-popover"
                                            v-if="scope.row.approvalStatus == 4||scope.row.approvalStatus==3"
                                            placement="bottom-end"
                                            :title="`${scope.row.approvalStatus == 4 ? '未通过' : '通过'}原因`"
                                            width="200"
                                            trigger="hover"
                                            :popper-class="scope.row.approvalStatus == 4?'not-through-popover-cont':null"
                                            :content="scope.row.approvalDesc||scope.row.passDesc||'无'">
                                    <p slot="reference">
                                       <span :style="{color:scope.row.approvalStatus == 3?'#606266':'#FF3B30'}">
                                           {{ scope.row.approvalStatus == 4 ? '未通过' : '已通过' }}
                                       </span>
                                        <i class="icon icon-not-adopt-tips"
                                           :style="{color:scope.row.approvalStatus == 3?'#606266':'#FF3B30'}"></i>
                                    </p>
                                </el-popover>
                            </template>
                        </el-table-column>
                        <el-table-column label="机构状态" width="80">
                            <template slot-scope="scope">
                                {{ scope.row.isClose | formatDesc(orgStatusList) }}
                            </template>
                        </el-table-column>
                        <el-table-column label="创建时间" width="100">
                            <template slot-scope="scope">
                                {{ scope.row.createTime | dateCus }}
                            </template>
                        </el-table-column>
                        <el-table-column
                            :class-name="('table-info-operation')"
                            label="操作"
                            width="100">
                            <template slot-scope="scope">
                                <!---总部机构用户 id默认为000000000000000000000000000000000000--->
                                <el-button
                                    v-if="scope.row.id!='000000000000000000000000000000000000'&&scope.row.approvalStatus != 1"
                                    @click="seeListInfoClick(scope.row.id)" type="text">
                                    查看
                                </el-button>
                                <el-button type="text"
                                           @click="editListInfoClick(scope.row.id)"
                                           v-if="!['2','5'].includes(scope.row.approvalStatus)&&scope.row.id!='000000000000000000000000000000000000'">编辑
                                </el-button>
                                <!--                                <el-button type="text"-->
                                <!--                                           :style="{color:scope.row.isClose === 0?'#FF3B30':'#606266'}"-->
                                <!--                                           @click="handleClose(scope.row)"-->
                                <!--                                           v-if="scope.row.id!='000000000000000000000000000000000000'">-->
                                <!--                                    {{scope.row.isClose === 0 ? '关闭':'开启'}}-->
                                <!--                                </el-button>-->
                                <el-popover
                                    placement="top"
                                    width="160"
                                    @show="handleClose(scope.row)"
                                    trigger="click"
                                    v-model="scope.row.isClosePopover">
                                    <p>确认{{ scope.row.isClose === 0 ? '关闭' : '开启' }}
                                        <span
                                            style="font-weight: bold">{{
                                                '&nbsp;' + scope.row.companyName + '&nbsp;'
                                            }}</span>吗？
                                    </p>
                                    <div style="text-align: right; margin: 0">
                                        <el-button size="mini" type="text" @click="hideClose(scope.$index)">取消
                                        </el-button>
                                        <el-button type="primary" size="mini" @click="closeOrg(scope.$index)">确定
                                        </el-button>
                                    </div>
                                    <el-button
                                        :style="{color:scope.row.isClose === 0?'#FF3B30':''}"
                                        slot="reference" type="text">{{ scope.row.isClose === 0 ? '关闭' : '开启' }}
                                    </el-button>
                                </el-popover>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="table-page-number">
                        <div class="block">
                            <el-pagination
                                @current-change="handleTablePageNumberChange"
                                :current-page.sync="companyListPage.currentPage"
                                :page-size="companyListPage.pageSize"
                                layout="total, prev, pager, next"
                                :total="companyListPage.totalPage">
                            </el-pagination>
                        </div>
                    </div>
                </div>


            </div>
        </el-main>
    </div>


</template>

<script type="text/ecmascript-6">
import {checkStringFun} from '@/common/js/Utils';
import comListSearch from '../../../components/comListSearch/index.vue'
import http from "@/common/js/http";

const companyTypeList = [{  // 机构类型列表
    typeLabel: '1', typeTitle: "汽车租赁公司"
}, {typeLabel: '2', typeTitle: "旅行社"}, {
    typeLabel: '3', typeTitle: "酒店"
}, {typeLabel: '4', typeTitle: "门店"}, {
    typeLabel: '5', typeTitle: "会展公司"
}, {typeLabel: '6', typeTitle: "电子商务公司"}, {
    typeLabel: '7', typeTitle: "企业单位"
}, {typeLabel: '8', typeTitle: "事业单位"}, {
    typeLabel: '9', typeTitle: "政府部门"
}];  // 机构类型列表
const companyRangeList = [{  // 经营范围列表
    rangeLabel: '1', rangeTitle: "车辆"
}, {rangeLabel: '2', rangeTitle: "导游"}, {
    rangeLabel: '3', rangeTitle: "门票"
}, {rangeLabel: '4', rangeTitle: "酒店"}, {
    rangeLabel: '5', rangeTitle: "机票"
}, {rangeLabel: '6', rangeTitle: "旅游（单元素）"}, {
    rangeLabel: '7', rangeTitle: "旅游（打包）"
}, {rangeLabel: '8', rangeTitle: "交通票"}, {
    rangeLabel: '9', rangeTitle: "其他"
}];  // 机构类型列表
const orgStatusList = [
    {value: 0, label: '正常'},
    {value: 1, label: '关闭'},
]
export default {
    name: 'mechanismList',
    //定义模版数据
    data() {
        return {
            tableH: 'calc(100vh - 360px)',
            orgStatusList,
            companyTypeState: {  // 审核状态、用户类型、合同
                screenAuditState: '0',  // 审核状态 0全部，1待提交，2待审核，3已通过，4未通过
                screenUserType: '1111',  // 用户类型搜索：全部1111，一级1000，二级0100，供应商0010，运营商0001
                isClose: null,
                screenContract: '0',  // 合同是否过期  0全部，1合同将过期，2合同已过期
                basicSeniorSwitch: false,  // 基本-高级==筛选切换
            },
            companyInfoInput: {  // 输入
                mechanismId: '',  // 机构id
                corporateName: '',  // 公司名称
                businessContName: '',  // 业务联系人姓名
                businessContMobile: '',  // 业务联系人手机号
                serviceCity: '',  // 服务城市
                serviceCityId: '',  // 服务城市
                businessNumber: '',  // 营业执照号码
                legalPersonName: '',  // 机构法人姓名
                legalPersonMobile: '',  // 机构法人手机
                financeName: '',  // 财务联系人姓名
                financeMobile: '',  // 财务联系人手机
            },
            companyTypeChoice: {  // 机构类型选择
                choiceAll: true,  // 全部
                isIndeterminate: false,
                choiceTypeSelect: ['1', '2', '3', '4', '5', '6', '7', '8', '9'],  // 选中机构类型[]
                typeLists: companyTypeList,  // 机构类型列表
                pushTypeLists: [],
            },
            companyRangeChoice: {  // 经营范围选择
                choiceAll: true,  // 全部
                isIndeterminate: false,
                choiceRangeSelect: ['1', '2', '3', '4', '5', '6', '7', '8', '9'],  // 选中经营范围选择[]
                rangeLists: companyRangeList,  // 经营范围列表
                pushRangeLists: [],
            },
            statisticalPageHead: {},  // 头部统计数据

            companyTable: [],  // table
            comTableLoading: false,
            companyListPage: {  // 列表分页
                pageSize: 10,  // 每页显示条目个数
                totalPage: 1,  // 总条目个数
                currentPage: 1,  // 当前页数
            },
            closeForm: {}, // 开启 关闭机构

        }
    },
    components: {comListSearch},
    //计算属性
    computed: {},
    //主件被加载完成
    mounted: function () {
    },
    activated: function () {
        this.organizationHeadCount();
        this.organizationList()
    },

    //定义事件方法
    methods: {
//            ====================
        createFilter(itemString) {
            return (restaurant) => {
                return (restaurant.value.indexOf(itemString) === 0);
            };
        },
        async galaxyCityList(serviceCityListString, cb) {  // 获取服务城市
            let form = {
                grade: 2,
                pageSize: 99999
            };
            try {
                let res = await http('/galaxyCity/list', form, 'POST');
                if (res.success) {
                    let serviceCityList = res.data.data;
                    serviceCityList.forEach(item => {
                        item.value = item.city;
                    })
                    let serviceCityListRes = serviceCityListString ? serviceCityList.filter(this.createFilter(serviceCityListString)) : serviceCityList;
                    // 调用 callback 返回建议列表的数据
                    cb(serviceCityListRes);
                }
//                    else {
                // this.messageError(res.errors[0].message);
//                        this.messageError('系统出错，请稍后!');
//                    }

            } catch (error) {
                this.messageError()
            }
        },
        galaxyCityId(item) {
            this.companyInfoInput.serviceCityId = item.shortCode;
        },
        async corporateNameList(corporateNameListString, cb) {  // 获取公司名称
            if (!corporateNameListString)
                return cb();

            let form = {
                pageIndex: 1,
                pageSize: this.pageSize,
                companyName: corporateNameListString
            };
            try {
                let res = await http('/galaxyOrgInfoApi/orgInfoApiPageList', form, 'POST');
                if (res.success) {
                    let corporateNameListS = res.data.data;
                    corporateNameListS.forEach(item => {
                        item.value = item.companyName;
                    })
//                        let corporateNameListRes = corporateNameListString ? corporateNameListS.filter(this.createFilter1(corporateNameListString)) : corporateNameListS;
                    // 调用 callback 返回建议列表的数据
                    cb(corporateNameListS);
                } else {
                    this.messageError('系统出错，请稍后!');
                }

            } catch (error) {
//                    this.messageError()
            }
        },
//            ======================
        btnBasicSeniorS() {  // 基本-高级==筛选切换
            this.companyTypeState.basicSeniorSwitch = !this.companyTypeState.basicSeniorSwitch;
        },
        checkTypeAllChange(val) {  // 机构类型全部选择
            this.companyTypeChoice.choiceTypeSelect = val ? companyTypeList : [];
            this.companyTypeChoice.isIndeterminate = false;
            if (val) {
                this.companyTypeChoice.pushTypeLists = [];
                this.companyTypeChoice.typeLists.forEach((item) => {
                    this.companyTypeChoice.pushTypeLists.push(item.typeLabel);
                    this.companyTypeChoice.choiceTypeSelect = this.companyTypeChoice.pushTypeLists

                })
            }
        },
        checkedTypeListChange(item) {  // 机构类型选中
            let checkedTypeLength = item.length;
            this.companyTypeChoice.choiceAll = checkedTypeLength === this.companyTypeChoice.typeLists.length;
            this.companyTypeChoice.isIndeterminate = checkedTypeLength > 0 && checkedTypeLength < this.companyTypeChoice.typeLists.length;
        },
        checkRangeAllChange(val) {  // 经营范围全部选择
            this.companyRangeChoice.choiceRangeSelect = val ? companyRangeList : [];
            this.companyRangeChoice.isIndeterminate = false;
            if (val) {
                this.companyRangeChoice.pushRangeLists = [];
                this.companyRangeChoice.rangeLists.forEach((item) => {
                    this.companyRangeChoice.pushRangeLists.push(item.rangeLabel);
                    this.companyRangeChoice.choiceRangeSelect = this.companyRangeChoice.pushRangeLists
                })
            }
        },
        checkedRangeListChange(item) {  // 机构类型选中
            let checkedRangeLength = item.length;
            this.companyRangeChoice.choiceAll = checkedRangeLength === this.companyRangeChoice.rangeLists.length;
            this.companyRangeChoice.isIndeterminate = checkedRangeLength > 0 && checkedRangeLength < this.companyRangeChoice.rangeLists.length;
        },
        screenResetClick() {  // 重置
            this.companyTypeState.screenAuditState = '0';  // 审核状态 0全部，1待提交，2待审核，3已通过，4未通过
            this.companyTypeState.screenUserType = '1111';  // 用户类型搜索：全部1111，一级1000，二级0100，供应商0010，运营商0001
            this.companyTypeState.screenContract = '0';  // 合同是否过期  0全部，1合同将过期，2合同已过期
            this.companyTypeState.isClose = 'null';  //

            this.companyInfoInput = {  // 输入
                mechanismId: '',  // 机构id
                corporateName: '',  // 公司名称
                businessContName: '',  // 业务联系人姓名
                businessContMobile: '',  // 业务联系人手机号
                serviceCity: '',  // 服务城市
                businessNumber: '',  // 营业执照号码
                legalPersonName: '',  // 机构法人姓名
                legalPersonMobile: '',  // 机构法人手机
                financeName: '',  // 财务联系人姓名
                financeMobile: '',  // 财务联系人手机
            };
            //=====机构类型  Begin=====\\
            this.companyTypeChoice.choiceTypeSelect = ['1', '2', '3', '4', '5', '6', '7', '8', '9'];  // 机构类型
            this.companyTypeChoice.isIndeterminate = false;  // 机构类型 全部标记
            this.companyTypeChoice.choiceAll = true;  // 机构类型选择
            //=====机构类型  End=====\\
            //=====经营范围  Begin=====\\
            this.companyRangeChoice.choiceRangeSelect = ['1', '2', '3', '4', '5', '6', '7', '8', '9'];  // 经营范围
            this.companyRangeChoice.isIndeterminate = false;  // 经营范围 全部标记
            this.companyRangeChoice.choiceAll = true;  // 机构类型选择
            //=====经营范围  End=====\\
            this.organizationList();
        },
        async organizationHeadCount() {  // 获取机构信息列表头部的统计数据
            try {
                let res = await http('/galaxyOrgInfoApi/doGalaxyOrgInfoApiPageHeadCount',);
                if (res.success) {
                    this.statisticalPageHead = res.data;
                }
//                    else {
                // this.messageError(res.errors[0].message);
//                        this.messageError('系统出错，请稍后!');
//                    }

            } catch (error) {
                this.messageError()
            }


        },
        screenBtnClick() {  // 搜索
            this.companyListPage.currentPage = 1;
            this.organizationList()

        },
        async organizationList() {  // 获取机构信息列表
            this.comTableLoading = true;
            let form = {
                pageIndex: this.companyListPage.currentPage,
                pageSize: this.companyListPage.pageSize
            };
            if (this.companyTypeState.screenAuditState) form.approvalStatus = this.companyTypeState.screenAuditState;// 审核状态
            if (this.companyTypeState.screenUserType) form.userType = this.companyTypeState.screenUserType;  // 用户类型
            if (this.companyTypeState.screenContract) form.contractExpired = this.companyTypeState.screenContract;  // 合同是否过期
            if (this.companyTypeState.isClose !== 'null') form.isClose = this.companyTypeState.isClose;  // 机构状态
            if (this.companyInfoInput.mechanismId) form.orgId = this.companyInfoInput.mechanismId;  // 机构id
            if (this.companyInfoInput.corporateName) form.companyName = this.companyInfoInput.corporateName;  // 公司名称
            if (this.companyInfoInput.businessContName) form.businessContractName = this.companyInfoInput.businessContName;  // 业务联系人姓名
            if (this.companyInfoInput.businessContMobile) form.businessContractPhone = this.companyInfoInput.businessContMobile;  // 业务联系人手机号
            if (this.companyInfoInput.serviceCityId) form.serverCity = this.companyInfoInput.serviceCityId;  // 服务城市
            if (this.companyInfoInput.businessNumber) form.businessLicenseNumber = this.companyInfoInput.businessNumber;  // 营业执照号码
            if (this.companyInfoInput.legalPersonName) form.legalPersonName = this.companyInfoInput.legalPersonName;  // 机构法人姓名
            if (this.companyInfoInput.legalPersonMobile) form.legalPersonPhone = this.companyInfoInput.legalPersonMobile;  // 机构法人手机
            if (this.companyInfoInput.financeName) form.financialContractName = this.companyInfoInput.financeName;  // 财务联系人姓名
            if (this.companyInfoInput.financeMobile) form.financialContractPhone = this.companyInfoInput.financeMobile;  // 财务联系人手机
            //=====机构类型  Begin=====\\
            if (this.companyTypeChoice.choiceTypeSelect) form.orgType = checkStringFun(9, this.companyTypeChoice.choiceTypeSelect);  // 机构类型
            //=====机构类型  End=====\\
            //=====经营范围  Begin=====\\
            if (this.companyRangeChoice.choiceRangeSelect) form.businessScope = checkStringFun(9, this.companyRangeChoice.choiceRangeSelect);   // 经营范围
            //=====经营范围  End=====\\
            try {
                let res = await http('/galaxyOrgInfoApi/orgInfoApiPageList', form, 'POST');
                if (res.success) {
                    this.comTableLoading = false;
                    let list = res.data.data
                    list.forEach(item => {
                        item.isClosePopover = false
                    })
                    this.companyTable = list
                    this.companyListPage.totalPage = res.data.total;  // 总条目个数
                } else {
                    this.messageError('系统出错，请稍后!');
                }
            } catch (error) {
                this.messageError()
            }
        },
        seeListInfoClick(id) {
            this.$router.replace('/LookMechanism/' + id);
        },
        editListInfoClick(id) {
            this.$router.replace('/editMechanism/' + id);
        },
        handleClose(item) {
            let {
                id,
                isClose
            } = item
            let form = {
                id
            }
            form.isClose = isClose === 0 ? 1 : 0
            this.closeForm = form
        },
        hideClose(index) {
            this.companyTable[index].isClosePopover = false
        },
        async closeOrg(index) {
            const res = await http('/galaxyOrgInfoApi/updateCloseOrg', this.closeForm, 'POST');
            if (res.success && res.data) {
                this.$message({
                    message: `${this.closeForm.isClose === 0 ? '开启' : '关闭'}成功`,
                    type: 'success'
                });
                this.hideClose(index)
                this.closeForm = {}
                await this.organizationList();
            }
        },
        handleTablePageNumberChange(val) {
            this.companyListPage.currentPage = val;
            this.organizationList();
        },

    },
    //监听模版变量
    watch: {}

}
</script>

